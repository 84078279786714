import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { parseISO } from 'date-fns';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return format(parseISO(value), "EEEE MM/dd/yyyy p");
  }

}
