export const environment = {
  production: true,
    firebase: {
      apiKey: "AIzaSyBdBR3ISax1xrN2O33m1l5SPp6WCwzKCFE",
      authDomain: "bull-city-summit.firebaseapp.com",
      databaseURL: "https://bull-city-summit-default-rtdb.firebaseio.com",
      projectId: "bull-city-summit",
      storageBucket: "bull-city-summit.appspot.com",
      messagingSenderId: "956632872338",
      appId: "1:956632872338:web:cdfbea364ea9d954243d4b",
      measurementId: "G-ZEBFGS088B"
    }
};