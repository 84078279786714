import { NgModule} from '@angular/core';
import { ObjectMapPipe } from './object-map.pipe';
import { TimePipe } from './time.pipe';
import { TruncatePipe } from './truncate.pipe';
import { DateTimePipe } from './date-time.pipe';
import { HeartsPipe } from './hearts.pipe';
import { OrderbyPipe } from './orderby.pipe';
import { SanitizePipe } from './sanitize.pipe';

@NgModule({
    imports: [
    ],
    declarations: [
    ObjectMapPipe,
    TimePipe,
    TruncatePipe,
    DateTimePipe,
    HeartsPipe,
    OrderbyPipe,
    SanitizePipe
  ],
    exports: [
      ObjectMapPipe,
      TimePipe,
      TruncatePipe,
      DateTimePipe,
      HeartsPipe,
      OrderbyPipe,
      SanitizePipe
    ],
    providers: [
      ObjectMapPipe,
      TimePipe,
      TruncatePipe,
      DateTimePipe,
      HeartsPipe,
      OrderbyPipe,
      SanitizePipe
    ]
  })
  
  export class SharedModule { }