import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { formatISO } from 'date-fns';

export interface Item { 
  first_name: string,
  last_name: string,
  email: string
 }

@Injectable({
  providedIn: 'root'
})

export class DataService {

  private document: AngularFirestoreDocument<Item>;
  public item: Observable<Item>;
  private postRef: AngularFireList<any>;
  public home = new BehaviorSubject<any>(null);
  public blog = new BehaviorSubject<any>(null);
  public about = new BehaviorSubject<any>(null);
  public types = new BehaviorSubject<any>(null);
  public podcast = new BehaviorSubject<any>(null);
  public podcasts = new BehaviorSubject<any>(null);
  public partners = new BehaviorSubject<any>(null);
  public images = new BehaviorSubject<any>(null);
  public previousKeynotes = new BehaviorSubject<any>(null);
  public previousHeadliners = new BehaviorSubject<any>(null);
  public previousSpeakers = new BehaviorSubject<any>(null);
  public logged = new BehaviorSubject<any>(null);
  private collection: AngularFirestoreCollection<any>;
  public user = new BehaviorSubject<any>(null);
  public screenWidth = new BehaviorSubject<any>(null);
  public playing = new BehaviorSubject<any>(null);
  public playlist = new BehaviorSubject<any>(null);
  public pages = new BehaviorSubject<any>(null);
  public artists = new BehaviorSubject<any>(null);
  public keynotes = new BehaviorSubject<any>(null);
  public speakers = new BehaviorSubject<any>(null);
  public editing = new BehaviorSubject<any>(null);
  public nav = new BehaviorSubject<any>(null);
  public press = new BehaviorSubject<any>(null);
  public newsItem = new BehaviorSubject<any>(null);
  public slides = new BehaviorSubject<any>(null);
  public eventslides = new BehaviorSubject<any>(null);
  public musicslides = new BehaviorSubject<any>(null);
  public applyslides = new BehaviorSubject<any>(null);
  public music = new BehaviorSubject<any>(null);
  public musicFooter = new BehaviorSubject<any>(null);
  public concerts = new BehaviorSubject<any>(null);
  public apply = new BehaviorSubject<any>(null);
  public badges = new BehaviorSubject<any>(null);
  public exclusives = new BehaviorSubject<any>(null);
  public boxoffice = new BehaviorSubject<any>(null);
  public art = new BehaviorSubject<any>(null);
  public tech = new BehaviorSubject<any>(null);
  public panels = new BehaviorSubject<any>(null);
  public presentations = new BehaviorSubject<any>(null);
  public schedule = new BehaviorSubject<any>(null);
  public hearts = new BehaviorSubject<any>(null);
  public detail = new BehaviorSubject<any>(null);
  public artists$ = new BehaviorSubject<any>(null);
  public logos$ = new BehaviorSubject<any>(null);
  public media = new BehaviorSubject<any>(null);
  public conference = new BehaviorSubject<any>(null);
  public videos = new BehaviorSubject<any>(null);
  public parag = new BehaviorSubject<any>(null);
  public festival = new BehaviorSubject<any>(null);
  public mobilePlatform = new BehaviorSubject<any>(null);
  mailChimpEndpoint = 'https://ugstrategies.us4.list-manage.com/subscribe/post-json?u=ea86e5393c045b9f67284e1ce&amp;id=ae8a8e2472';

  public gallery = new BehaviorSubject<any>(null);
  _links;

  constructor(
    private db: AngularFireDatabase,
    private afs: AngularFirestore,
    private http: HttpClient,
    private router: Router,
    private storage: Storage,
    private functions: AngularFireFunctions
  ) { 
    //this.addBlog();
  }

  homeSliderPublishState(val){
    this.afs.doc('slides/' + val.id).update({
      on: val.on
    })
  }

  addBlog(){
    this.afs.collection('blog').add({
      action: 'RE-LIVE BCS 2023',
      action_icon: 'logo-youtube',
      action_link: '/media',
      subtitle: 'December 6, 2023',
      youtube: 'https://www.youtube.com/embed/gPDNXPwUpOE?si=cHDn51fr9l1h5wo1',
      order: 0,
      slug: 'presentation-creative-populism-bcs-2023',
      image: 'https://firebasestorage.googleapis.com/v0/b/bull-city-summit.appspot.com/o/carl-slide-news.jpg?alt=media&token=df846723-a285-4936-a641-7e9e18e0cc8e',
      title: 'Can Creative Populism Save America?',
      category: 'BCS Presentation',
      description: "",
      content: "As long as our nation is fiercely divided, we can't attend to the challenges we face nor fully develop the opportunities presenting themselves. In this talk from Durham-based Author, Entrepreneur, and Professor Carl Nordgren, you'll learn about the concept of Creative Populism - and the notion that humans are inherently born as fully creative beings. Nordgen speaks that through human creative entrepreneurship there is a common ground proven to be as attractive to the political left, as to the right, in solving many of the issues we face today as a society."
    })
  }


  addType(i){
    this.afs.doc('photos/' + i.key).update({
      type: 'speakers'
    });
  }

  publish(bool, event){
    this.afs.doc('schedule/' + event.id).update({
      publish: bool
    })
  }

  addLogo(logo, event){
    //console.log(logo, event);
    this.afs.collection('schedule/' + event.id + '/logos').add({
      image: logo
    })
  }

  addPhoto(photo, i){
    this.afs.collection('photos').add({
      image: photo,
      order: i
    })
  }

  updatePhoto(key, i){
    this.afs.doc('photos/' + key).update({
      order: i
    })
  }

  deleteEvent(item){
    this.afs.doc('schedule/' + item.id).delete();
  }

  updateType(type, item){
    this.afs.doc('schedule/' + item.id).update({
      type: type
    })
  }

  addPodcast(){
    this.afs.doc('podcasts/episode-019').set({
      date: '10/01/23',
      description: 'Parag Bhandari sits down with Soul Music Legend, Lee Fields and Rock Legend, Anders Osborne at the premiere of Bull City Summit. Join the conversation as we discuss everything from touring overseas to surviving the pandemic.',
      file: 'https://firebasestorage.googleapis.com/v0/b/bull-city-summit.appspot.com/o/podcast-episode-19.mp3?alt=media&token=89b2c7f2-aeeb-4424-bb44-09c70ef8eeba&_gl=1*1bsllow*_ga*MTc4NTg5NTc1MC4xNjk2MjY1MzYw*_ga_CW55HF8NVT*MTY5ODg1OTY1My41MC4xLjE2OTg4NjA0NzUuMzQuMC4w',
      id: 19,
      image: 'https://firebasestorage.googleapis.com/v0/b/bull-city-summit.appspot.com/o/podcast-episode-19.jpg?alt=media&token=df3d518e-c496-4b36-96fb-182e2b861918&_gl=1*11ok6wd*_ga*MTc4NTg5NTc1MC4xNjk2MjY1MzYw*_ga_CW55HF8NVT*MTY5ODg1OTY1My41MC4xLjE2OTg4NjAzNzUuNDkuMC4w',
      subtitle: 'Interview',
      title: 'Lee Fields and Anders Osborne'
    }).then(()=>{
      this.afs.doc('podcasts/episode-020').set({
        date: '11/01/23',
        description: "A conversation with A Place to Bury Strangers' frontman, Oliver Ackermann. APTBS is an American rock band formed in 2002, in New York City. The trio is currently composed of Oliver Ackermann, John Fedowitz and Sandra Fedowitz. The band plays a heavy, atmospheric Wall of Sound–influenced blend of noise rock, shoegaze and space rock. Hear how the loudest band in the world got its start right here on the BCS Podcast.",
        file: 'https://firebasestorage.googleapis.com/v0/b/bull-city-summit.appspot.com/o/podcast-episode-20.jpg?alt=media&token=ad71e779-cfe0-4217-aef0-fb80d610d30c&_gl=1*1wksw8m*_ga*MTc4NTg5NTc1MC4xNjk2MjY1MzYw*_ga_CW55HF8NVT*MTY5ODg1OTY1My41MC4xLjE2OTg4NjA0OTAuMTkuMC4w',
        id: 20,
        image: 'https://firebasestorage.googleapis.com/v0/b/bull-city-summit.appspot.com/o/podcast-episode-20.jpg?alt=media&token=ad71e779-cfe0-4217-aef0-fb80d610d30c&_gl=1*1od2ooh*_ga*MTc4NTg5NTc1MC4xNjk2MjY1MzYw*_ga_CW55HF8NVT*MTY5ODg1OTY1My41MC4xLjE2OTg4NjAzOTUuMjkuMC4w',
        subtitle: 'Interview',
        title: 'Oliver Ackermann'
      })
    })
  }

  addMe(){
    this.afs.collection('speakers').add({
      name: 'Pete McEntegart',
      description: 'Managing Editor, GrepBeat',
      image: 'https://firebasestorage.googleapis.com/v0/b/bull-city-summit.appspot.com/o/moreartists%2Fpete-mcentegart.jpg?alt=media&token=e4e374e6-edc3-46a7-8e97-8b2e33cfb798',
      website: 'https://grepbeat.com/author/pete/',
      linkedin: 'https://www.linkedin.com/in/pete-mcentegart/'
    })
  }

  artistPhoto(url, artist, event){
    console.log(url, artist, event);
    this.afs.doc('schedule/' + event.id + '/artists/' + artist.id).update({
      image: url
    });
  }

  eventLogo(url, logo, event){
    this.afs.doc('schedule/' + event.id + '/logos/' + logo.id).update({
      image: url
    });
  }

  removeLogo(x, d){
    this.afs.doc('schedule/' + d.id + '/logos/' + x.id).delete();
  }

  removeArtist(x, d){
    this.afs.doc('schedule/' + d.id + '/artists/' + x.id).delete();
  }

  getMainSlider(){
    return this.afs.collection('slides', ref => ref.orderBy('order'))
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
  }

  getMusicSlider(){
    return this.afs.collection('music-slides', ref => ref.orderBy('order'))
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
  }

  getPosts(){
    return this.afs.collection('blog', ref => ref.orderBy('order'))
    .snapshotChanges()
    .pipe(
      map((snaps)=>
      snaps.map((snap)=> {
        return {
          id: snap.payload.doc.id,
          ...(snap.payload.doc.data() as {})
        };
      }))
    )
  }

  getPhotos(){
    this.collection = this.afs.collection('photos', ref => ref.orderBy('order'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.gallery.next(actions);
      }
    })
  }

  getDetailArtists(data){
    this.collection = this.afs.collection('schedule/' + data.id + '/artists');
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.artists$.next(actions);
      }
    })
  }

  getDetailLogos(data){
    this.collection = this.afs.collection('schedule/' + data.id + '/logos');
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.logos$.next(actions);
      }
    })
  }

  getSchedule(){
    this.collection = this.afs.collection('schedule', ref=>ref.orderBy('date'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.schedule.next(actions);
      }
      
    })
  }

  sendPromo(first_name, last_name, email, phone){
    if(phone != ''){
      this.afs.collection('road-to-bcs').add({
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone
      })
    } else {
      this.afs.collection('road-to-bcs').add({
        first_name: first_name,
        last_name: last_name,
        email: email
      })
    }
    const callable = this.functions.httpsCallable('sendPromoMail');
    const obs = callable({ 
      name: first_name + ' ' + last_name,
      email: email,
      message: 'Thanks for joining us on the road to BCS!<br>Your 15% off discount code is: GB4XEDDQTTDV<br><a href="https://thatdamnwagon.com/discount/GB4XEDDQTTDV">Click here to receive your Groovewagon discount.</a>'
    });
    this._links = obs.subscribe(async res => {
      if(res){
        this._links.unsubscribe();
      }
    });
  }

  addArtists(name, item){
    this.afs.collection('schedule/' + item.id + '/artists').add({
      name: name
    });
  }

  addThisArtist(name, image, item){
    this.afs.collection('schedule/' + item.id + '/artists').add({
      name: name,
      image: image
    })
  }

  eventImage(url, event){
    this.afs.doc('schedule/' + event.id).update({
      image: url
    })
  }

  addEvent(name, description, content, venue, address, date, end, image, type){
    console.log(name, description, content, venue, address, date, end, image);
    this.afs.collection('schedule').add({
      name: name,
      description: description,
      venue: venue,
      address: address,
      date: date,
      end: end,
      content: content,
      image: image,
      type: type
    })
  }

  updateStartTime(time, item){
    this.afs.doc('schedule/' + item.id).update({
      date: time
    });
  }

  updateEndTime(time, item){
    this.afs.doc('schedule/' + item.id).update({
      end: time
    });
  }

  addLogos(name, item){
    this.afs.collection('schedule/' + item.id + '/logos').add({
      name: name
    });
  }

  updateName(name, item){
    this.afs.doc('schedule/' + item.id).update({
      name: name
    })
  }

  updateDescription(description, item){
    this.afs.doc('schedule/' + item.id).update({
      description: description
    })
  }

  updateContent(content, item){
    this.afs.doc('schedule/' + item.id).update({
      content: content
    })
  }

  updateAddress(address, item){
    this.afs.doc('schedule/' + item.id).update({
      address: address
    })
  }

  updateVenue(venue, item){
    this.afs.doc('schedule/' + item.id).update({
      venue: venue
    })
  }

  copyKeynote(keynote){
    this.afs.collection('speakers').add(keynote);
  }

  addArtist(){
    this.afs.collection('keynotes').add({
      name: 'Beth Palmer',
      image: 'https://firebasestorage.googleapis.com/v0/b/bull-city-summit.appspot.com/o/beth-palmer.jpg?alt=media&token=f18d5d20-bfe4-411f-9262-c6db001fee0e',
      linkedin: 'https://www.linkedin.com/in/beth-palmer-1679242a',
      website: 'https://bethpalmerstudio.com/',
      order: 9
  })
  }

  getApply(){
    this.collection = this.afs.collection('pages/apply/sections', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.apply.next(actions);
    })
  }

  getBadges(){
    this.collection = this.afs.collection('pages/badges/sections', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.badges.next(actions);
    })
  }

  getArt(){
    this.collection = this.afs.collection('pages/art/sections', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.art.next(actions);
    })
  }

  getTech(){
    this.collection = this.afs.collection('pages/tech/sections', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.tech.next(actions);
    })
  }

  getExclusives(){
    this.collection = this.afs.collection('exclusives', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.exclusives.next(actions);
    })
  }

  getBoxoffice(){
    this.collection = this.afs.collection('boxoffice', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.boxoffice.next(actions);
    })
  }

  getPanels(){
    this.collection = this.afs.collection('pages/panels/sections', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.panels.next(actions);
    })
  }

  getPresentations(){
    this.collection = this.afs.collection('panels', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.presentations.next(actions);
    })
  }

  

  setNews(val){
    this.newsItem.next(val);
  }

  addPress(){
    this.afs.collection('press').add({
      date: 'August 31, 2023',
      image: 'https://firebasestorage.googleapis.com/v0/b/bull-city-summit.appspot.com/o/press-grep-2023.jpg?alt=media&token=c4880101-9342-4e3c-848f-927314be7fa9&_gl=1*1oiop9k*_ga*NDk1MzYwMzk2LjE2OTAyMjYzNDc.*_ga_CW55HF8NVT*MTY5NTkxNjgzMC4yMjYuMS4xNjk1OTE3Njk2LjMwLjAuMA..',
      link: 'https://grepbeat.com/2023/08/31/innovative-tech-and-arts-conference-bull-city-summit-returns-to-durham-for-2023/',
      name: 'Innovative Tech And Arts Conference Bull City Summit Returns To Durham For 2023',
      order: 1,
      type: 'press'
    }).then(()=>{
      this.afs.collection('press').add({
        date: 'September 18, 2023',
        image: 'https://firebasestorage.googleapis.com/v0/b/bull-city-summit.appspot.com/o/press-magnetic-2023.jpg?alt=media&token=c69dcb43-5c06-4a16-bcbb-af9af2f18ae0&_gl=1*1qf2yqm*_ga*NDk1MzYwMzk2LjE2OTAyMjYzNDc.*_ga_CW55HF8NVT*MTY5NTkxNjgzMC4yMjYuMS4xNjk1OTE3NzUyLjYwLjAuMA..',
        link: 'https://www.magneticmag.com/2023/09/electro-pulse-of-the-south-bull-city-summit-amplifies-durhams-electronic-music-revolution/',
        name: "Electro-Pulse of the South: Bull City Summit Amplifies Durham's Electronic Music Revolution",
        order: 1,
        type: 'press'
      }).then(()=>{
        this.afs.collection('press').add({
          date: 'August 17, 2023',
          image: 'https://firebasestorage.googleapis.com/v0/b/bull-city-summit.appspot.com/o/press-ral-2023.jpg?alt=media&token=c0827599-eb49-40d2-ba3c-7bb741abd08a&_gl=1*mx3otu*_ga*NDk1MzYwMzk2LjE2OTAyMjYzNDc.*_ga_CW55HF8NVT*MTY5NTkxNjgzMC4yMjYuMS4xNjk1OTE3ODA0LjguMC4w',
          link: 'https://raltoday.6amcity.com/culture/bull-city-summit-raleigh-durham-nc',
          name: "Everything you need to know about this year's Bull City Summit",
          order: 1,
          type: 'press'
        })
      })
    })
  }

  getPress(){
    this.collection = this.afs.collection('press', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.press.next(actions);
    })
  }

  getConcerts(){
    this.collection = this.afs.collection('concerts', ref=>ref.orderBy('order'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.concerts.next(actions);
      }
    })
  }

  getEventSlides(){
    this.collection = this.afs.collection('event-slides', ref=>ref.orderBy('order'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.eventslides.next(actions);
      }
    })
  }

  getApplySlides(){
    this.collection = this.afs.collection('apply-slides', ref=>ref.orderBy('order'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.applyslides.next(actions);
      }
    })
  }

  getMusicSlides(){
    this.collection = this.afs.collection('music-slides', ref=>ref.orderBy('order'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.musicslides.next(actions);
      }
    })
  }

  getSlides(){
    this.collection = this.afs.collection('slides', ref=>ref.orderBy('order'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.slides.next(actions);
      }
    })
  }

  getSpeakers(){
    this.collection = this.afs.collection('speakers', ref=>ref.orderBy('name'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.speakers.next(actions);
      }
    })
  }

  getKeynotes(){
    this.collection = this.afs.collection('keynotes', ref=>ref.orderBy('order'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.keynotes.next(actions);
      }
    })
  }

  getArtists(){
    this.collection = this.afs.collection('artists', ref=>ref.orderBy('name'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.artists.next(actions);
      }
    })
  }

  getBlog(){
    this.collection = this.afs.collection('blog', ref=>ref.orderBy('order'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.blog.next(actions);
      }
    })
  }

  updateAbout(){
    this.collection = this.afs.collection('pages/partners/sections');
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      console.log(actions);
      if(actions){
        this.updateEach(actions);
      }
    })

  }

  updateEach(val){
    for(let i = 0; i < val.length; i++){
      console.log(val[i])
      if(i == 2){
        this.collection = this.afs.collection('pages');
        this.collection.doc('about').collection('sections').doc('section4').set(val[i]).then(()=>{
          console.log('set');
        })
      }
    }
  }

  getPages(){
    this.collection = this.afs.collection('pages');
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        this.pages.next(actions);
      }
      
    })
  } 

  getDoc(val){
    this.collection = this.afs.collection('pages/' + val + '/sections', ref=>ref.orderBy('order'));
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        console.log(actions)
        this.editing.next(actions);
      }
      
    })
  }

  postMessage(name, email, message){
    let data = [
      {
        name: name,
        email: email,
        message: message
      }
    ];
    let url =  "https://mail-tdu2kqpywq-uc.a.run.app"
    this.http.post(url,
      data,
      {responseType: 'text'}
      ).toPromise().then((data:any)=>{
      console.log(data)
      });
  }

  subscribeToList(data) {
    const params = new HttpParams()
      .set('FNAME', data.firstName)
      .set('LNAME', data.lastName)
      .set('EMAIL', data.email)
      .set('group[24413][128]', 'true')
      .set('b_b736eb9e9077236cbd681a53b_4b66a82360', '');
    const mailChimpUrl = `${this.mailChimpEndpoint}&${params.toString()}`;
    return this.http.jsonp(mailChimpUrl, 'c')
  }

async setData(){
  this.afs.collection('blog').add({
    title: 'lorem ipsum',
    subtitle: 'testing 123'
  })
}

async setBlog(){
  let date = Date.now()
  this.collection = this.afs.collection('pages/home/sections/section6/blog')
  this.collection.doc(date.toString()).set({
    title: "Lorem Ipsum",
    description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    date: date
  })
}
  async setEvent(){
    //let id = '16';
    this.collection = this.afs.collection('pages');
    this.collection.doc('about').collection('sections').doc('section2').set({
      action: "Contact BCS",
      background: "https://firebasestorage.googleapis.com/v0/b/bull-city-summit.appspot.com/o/about2.jpeg?alt=media&token=8bffd48d-f2d3-43e6-aeee-cba09fe657b2",
      bottom: 175,
      description: "Bull City Summit (BCS) is a new music, art, science and technology showcase festival & business conference held annually in Durham, North Carolina. BCS gathers creative visionaries, entrepreneurs, and thought-leaders to support music and the arts, further our relationship with science and technology, and address the challenges of our ever-changing world.",
      icon: "reader",
      id: "section2",
      link: "mailto: info@bullcitysummit.com",
      order: 2,
      title: "Interested in BCS?"
    }).then(()=>{
      console.log(true);
    })
  }

  signingIn(){
    this.router.navigateByUrl('/account');
  }

  async setUser(res, id){
    //console.log(res, id);
    this.collection = this.afs.collection('users');
    this.collection.doc(id).set({
      first_name: res.first_name,
      last_name: res.last_name,
      email: res.email
    }).then(()=>{
      //console.log(id);
      this.getUser(id);
      //this.storage.get('bcsId').then(val=>{
        //if(val && val != null){
          
        //} else {
          //this.router.navigateByUrl('/home');
        //}
      //});      
      return true;
    })
  }

  getUser(res){
    this.document = this.afs.collection('users').doc(res);
    this.document
    .snapshotChanges()
    .subscribe(val=>{
      //console.log(val);
      let i = { id: val.payload.id, ...val.payload.data() };
      this.user.next(i);
    })
  }

  getHearts(id){
    this.collection = this.afs.collection('users/' + id + '/hearts');
    this.collection
    .snapshotChanges()
    .pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            key: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          };
        }),
      )
    )
    .subscribe(actions=>{
      if(actions){
        //console.log(actions)
        this.hearts.next(actions);
      }
      
    })
  }

  heart(item, user){
    this.afs.collection('users/' + user + '/hearts').add({
      id: item
    });
  }

  unheart(item, user){
    this.afs.doc('users/' + user + '/hearts/' + item).delete();
  }

  getHome(){
    this.collection = this.afs.collection('pages/home/sections', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.home.next(actions);
    })
  }

  getAbout(){
    this.collection = this.afs.collection('pages/about/sections');
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.about.next(actions);
    })
  }

  getPartners(){
    this.collection = this.afs.collection('pages/partners/sections');
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.partners.next(actions);
    })
  }

  getImages(){
    this.document = this.afs.doc('pages/media');
    this.document
    .valueChanges()
    .subscribe(val=>{
      //console.log(val);
      this.images.next(val);
    })
  }

  getPodcast(){
    this.collection = this.afs.collection('pages/podcasts/sections');
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.podcast.next(actions);
    })
  }

  getParag(){
    this.collection = this.afs.collection('pages/parag-bhandari/sections');
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.parag.next(actions);
    })
  }

  getPodcasts(){
    this.collection = this.afs.collection('podcasts');
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      //this.playlist.next(actions);
      this.podcasts.next(actions.reverse());
    })
  }

  getPreviousKeynotes(){
    this.collection = this.afs.collection('pages/festival-recap/keynotes');
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.previousKeynotes.next(actions);
    })
  }

  getPreviousHeadliners(){
    this.collection = this.afs.collection('pages/festival-recap/headliners');
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.previousHeadliners.next(actions);
    })
  }

  getPreviousSpeakers(){
    this.collection = this.afs.collection('pages/festival-recap/speakers', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.previousSpeakers.next(actions);
    })
  }

  getMusicFooter(){
    this.document = this.afs.doc('pages/music')
    this.document
    .valueChanges()
    .subscribe(actions=>{
      this.musicFooter.next(actions);
    })
  }

  getMusic(){
    this.collection = this.afs.collection('pages/music/sections', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.music.next(actions);
    })
  }

  getMedia(){
    this.collection = this.afs.collection('pages/media/sections', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.media.next(actions);
    })
  }

  getConference(){
    this.collection = this.afs.collection('pages/conference/sections', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.conference.next(actions);
    })
  }

  getVideos(){
    this.collection = this.afs.collection('videos', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.videos.next(actions);
    })
  }

  getFestival(){
    this.collection = this.afs.collection('pages/festival/sections', ref => ref.orderBy('order'));
    this.collection
    .valueChanges()
    .subscribe(actions=>{
      this.festival.next(actions);
    })
  }

}
