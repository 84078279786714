import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let s = value.toString();
    let d = new Date(s)
    let options = {
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric'
    };

    return d.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'})
  }

}
