import { Component, OnInit, HostListener } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { DataService } from './services/data.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, StatusBarStyle, Style } from '@capacitor/status-bar';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  pages;
  mobilePages;
  playing;
  playlist;
  width;
  public forward : string = "assets/forward.svg" ;
  public backward : string = "assets/backward.svg";
  progress;
  buffer;
  user;
  //background: string = '#190066';
  background: string = '#3d4148';

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.data.screenWidth.next(window.innerWidth);
  }

  constructor(
    private menu: MenuController,
    private storage: Storage,
    private data: DataService,
    private platform: Platform
  ) {}

  fastforward(){
    const players = document.querySelectorAll('audio');
			players.forEach(element => {
        let time = element.currentTime;
				let newTime = time + 30;
        element.currentTime = newTime;
		});
  }

  rewind(){
    const players = document.querySelectorAll('audio');
			players.forEach(element => {
        let time = element.currentTime;
				let newTime = time - 30;
        element.currentTime = newTime;
		});
  }

  stop(){
    this.data.playlist.next(null);
    this.data.playing.next(null);
  }

  onEnded(event){
    this.stop();
  }

  adminMenu(){
    this.pages = [
      {
        title: 'Home',
        url: '/home',
        icon: 'home'
      },
      {
        title: 'Artists',
        url: '/artists',
        icon: 'people'
      },
      {
       title: 'Podcast',
        url: '/podcast',
        icon: 'radio'
      },
      {
        title: 'Partners',
        url: '/partners',
        icon: 'star'
      },
      //{
       // title: 'Media',
       // url: '/media',
       // icon: 'images'
      //},
      {
        title: 'About',
        url: '/about',
        icon: 'information-circle'
      },
      {
        title: 'Account',
        url: '/account',
        icon: 'person-circle'
      },
      {
        title: 'Apply',
        url: '/apply',
        icon: 'document-text'
      },
      {
        title: 'Edit',
        url: '',
        icon: 'create'
      }
    ];
  }

  setMenu(val){
    if(val == true){
      this.pages = [
        {
          title: 'Home',
          url: '/home',
          icon: 'home'
        },
        {
          title: 'Summit',
          url: '/tickets',
          icon: 'home'
        },
        {
          title: 'Media',
           url: '/media',
           icon: 'radio'
         },
        {
          title: 'Podcast',
           url: '/podcast',
           icon: 'radio'
         },
       {
        title: '2023',
        url: 'dropdown',
        icon: 'podium'
       }
      ];
      this.mobilePages = [
        {
          title: 'Home',
          url: '/home',
          icon: 'home'
        },
        {
          title: 'Summit',
          url: '/tickets',
          icon: 'home'
        },
        {
          title: 'Conference',
          url: '/conference',
          icon: 'home'
        },
        {
          title: 'Festival',
          url: '/festival',
          icon: 'home'
        },
        {
          title: 'Media',
           url: '/media',
           icon: 'radio'
         },
        {
         title: 'Podcast',
          url: '/podcast',
          icon: 'radio'
        },
        {
          title: '2023 Concerts',
          url: '/music',
          icon: 'home'
         },
         {
          title: '2023 Artists',
          url: '/artists',
          icon: 'home'
         },
         {
          title: '2023 Speakers',
          url: '/speakers',
          icon: 'home'
         },
      ];
      this.data.nav.next(this.pages);
    } else {
      this.pages = [
        {
          title: 'Home',
          url: '/home',
          icon: 'home'
        },
        {
          title: 'Summit',
          url: '/tickets',
          icon: 'home'
        },
        {
          title: 'Podcast',
           url: '/podcast',
           icon: 'radio'
         },
         {
          title: 'Media',
           url: '/media',
           icon: 'radio'
         },
       {
        title: '2023',
        url: 'dropdown',
        icon: 'podium'
       }
      ];
      this.mobilePages = [
        {
          title: 'Home',
          url: '/home',
          icon: 'home'
        },
        {
          title: 'Summit',
          url: '/tickets',
          icon: 'home'
        },
        {
          title: 'Conference',
          url: '/conference',
          icon: 'home'
        },
        {
          title: 'Festival',
          url: '/festival',
          icon: 'home'
        },
        {
         title: 'Podcast',
          url: '/podcast',
          icon: 'radio'
        },
        {
          title: 'Media',
           url: '/media',
           icon: 'radio'
         },
        {
          title: '2023 Concerts',
          url: '/music',
          icon: 'home'
         },
         {
          title: '2023 Artists',
          url: '/artists',
          icon: 'home'
         },
         {
          title: '2023 Speakers',
          url: '/speakers',
          icon: 'home'
         },
      ]
      this.data.nav.next(this.pages);
    }
  }

  addListener(){
    const players = document.querySelectorAll('audio');
              players.forEach(element => {
                //console.log(element);
                element.addEventListener('timeupdate', ()=>{
                  //console.log(element.currentTime, element.duration);
                  this.progress = element.currentTime / element.duration;
                  this.buffer = element.duration;
                  //console.log(this.progress);
                });
              });
  }

  ngOnInit(){
    this.pages = [];
    this.platform.ready().then(async () => {
      if(this.platform.is('android')){
        StatusBar.setBackgroundColor({
          color: this.background
        });
        this.data.mobilePlatform.next('android');
      }
      if(this.platform.is('ios')){
        StatusBar.setStyle({
          style: Style.Dark
        });
        this.data.mobilePlatform.next('ios');
      }
      await this.storage.create();
      this.storage.get('bcsId').then((res)=>{
        //console.log(res);
        if(res && res != null){
          this.data.logged.next(true);
          this.data.getUser(res);
        }
      });
      this.onResize();
      await SplashScreen.hide();
    });
    this.data.logged.subscribe(val=>{
      if(val){
        this.setMenu(true);
      } else {
        this.setMenu(false);
      }
    });
    this.data.playlist.subscribe(val=>{
      if(val){
        this.playlist = val;
        //console.log(val);
      }
    })
    this.data.playing.subscribe(val=>{
      this.playing = val;
      if(val){
        setTimeout(()=>{
          this.addListener();
        }, 500);
      }
    });
    this.data.screenWidth.subscribe(val=>{
      if(val){
        this.width = val;
      }
    })
  }

  closeMenu(){
    this.menu.close();
  }

  
}
