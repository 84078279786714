import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectMap'
})
export class ObjectMapPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var obj = value;
    //console.log(value)

    if(obj && obj != null){
      return Object.keys(obj).reduce(function(res, v) {
        let key = {
          key: v
        };
        var obv = Object.assign({}, obj[v], key);
        //console.log(res.concat(obv));
        return res.concat(obv);
    }, []);
    } else {
      return null;
    }


  }

}
